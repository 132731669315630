import React, { useState, useEffect } from "react"
import styled from "styled-components"
// Components
import { NavLink } from "./Links"
import { NavHeading } from "./Headings"
import DropdownButton from "./DropdownButton"
// custom hook
import useScreenWidth from "./useScreenWidth"

const NavBar = props => {
  const screenWidth = useScreenWidth()

  return (
    <Wrapper>
      <div className="content">
        <div className="heading">
          <NavHeading>Jake & Allie</NavHeading>
        </div>
        {screenWidth <= 768 ? (
          <div className="dropdown">
            <DropdownButton onDropdownOpen={props.onDropdownOpen} />
          </div>
        ) : (
          <div className="links">
            <NavLink href="#section-title">Home</NavLink>
            <NavLink
              href="https://www.crateandbarrel.com/gift-registry/allison-finn/r6573451"
              target="_blank"
              rel="noopener noreferrer"
            >
              Registry
            </NavLink>
            <NavLink href="#section-info">Info</NavLink>
            <NavLink href="#section-accommodations">Accommodations</NavLink>
            <NavLink href="#section-schedule">Schedule</NavLink>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default NavBar

const Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  background-color: rgba(46, 46, 46, 0.9);
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
  }

  .heading {
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .links {
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .dropdown {
    width: 25px;
    height: 25px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`
