import styled from "styled-components"

export const NavHeading = styled.h1`
  color: var(--color-primary-text);
  font-size: 2rem;
`

export const Heading1 = styled.h1`
  color: ${props => (props.color ? props.color : "black")};
  font-size: clamp(4rem, 7vw, 10rem);
  text-transform: uppercase;
  margin: 0.25rem 0;
`

export const Heading2 = styled.h2`
  color: ${props => (props.color ? props.color : "black")};
  font-size: clamp(1.5rem, 2vw, 3rem);
  text-transform: capitalize;
  margin: 0 2rem;
`
export const HeadingInfo = styled(Heading2)`
  color: var(--color-heading-gold);
  font-size: clamp(1.5rem, 3vw, 3rem);
  margin: 0 0;
`

export const HeadingSection = styled.h2`
  color: ${props => (props.color ? props.color : "black")};
  font-size: clamp(3rem, 3.2vw, 6rem);
  margin: 0 0 5rem 0;
  border-bottom: 2px solid ${props => (props.color ? props.color : "black")};
  border-top: 2px solid ${props => (props.color ? props.color : "black")};
  display: inline-block;
  text-align: center;
`
