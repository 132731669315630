import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  background-color: transparent;
  height: 100%;
  width: 25px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const Line = styled.div`
  display: block;
  width: 100%;
  height: 20%;
  background-color: #fff;
`

const DropdownButton = props => {
  return (
    <Wrapper onClick={e => props.onDropdownOpen()}>
      <Line />
      <Line />
      <Line />
    </Wrapper>
  )
}

export default DropdownButton
