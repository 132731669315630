import React from "react"
import styled from "styled-components"
// image
import background from "../images/bedford-bedroom.jpg"
// components
import { HeadingSection } from "./Headings"
import HotelCard from "./HotelCard"

export const AccommodationsSection = () => {
  return (
    <Wrapper id="section-accommodations">
      <HeadingSection color="#fff">Accommodations</HeadingSection>
      <Row>
        <Cell>
          <HotelCard
            name="Beford Village Inn"
            street="2 Olde Bedford Way"
            city="Bedford, New Hampshire 03110"
            link="https://be.synxis.com/?Hotel=75787&Chain=10237&Dest=BEDFORDVILLA&config=MHTTG&theme=MHTTG&locale=en-US&arrive=12/30/2022&depart=1/1/2023&adult=2&child=0&group=FNN22GB"
          />
        </Cell>
        <Cell>
          <HotelCard
            name="Hilton Garden Inn"
            street="101 South Commercial Street"
            city="Manchester, New Hampshire 03101"
            link="https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=MHTDTGI&groupCode=FSW&arrivaldate=2022-12-30&departuredate=2023-01-01&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT"
          />
        </Cell>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url(${background});
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;
  display: block;
  padding: 7rem 0;
  width: 100%;
  min-height: 75vh;
  text-align: center;

  @media (max-width: 768px) {
    min-height: 100vh;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 33%;
  margin: 0 1rem;

  @media (max-width: 768px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`
