import React from "react"
import styled from "styled-components"
import { HeadingSection } from "./Headings"

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 7rem 0;
  width: 100%;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    :not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  @media (max-width: 768px) {
    min-height: 100vh;
  }
`

const ShuttleText = styled.i`
  width: 50%;
  text-align: center;
`

const ScheduleSection = () => {
  return (
    <Wrapper id="section-schedule">
      <HeadingSection>Schedule of Events</HeadingSection>
      <h1>Ceremony</h1>
      <p>6:30 pm - 7:00 pm</p>
      <h1>Cocktail Hour</h1>
      <p>7:00 pm - 8:00 pm</p>
      <h1>Reception</h1>
      <p>8:00 pm - 12:30 am</p>
      <ShuttleText>
        Shuttle service will be provided for guests staying at the Hilton Garden
        Inn. A detailed schedule of pick-up and drop-off times will be provided
        in October. Please check back for updates and additional details.{" "}
      </ShuttleText>
    </Wrapper>
  )
}

export default ScheduleSection
