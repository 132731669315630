import styled from "styled-components"

export const Unordered = styled.ul`
  text-align: center;
  line-height: 1.5;
  margin-top: 2rem;
`

export const ListItem = styled.li`
  list-style: none;
  font-size: clamp(1.5rem, 1.5vw, 3rem);
`
