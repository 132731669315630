import React from "react"
import styled from "styled-components"
// components
import { HeadingInfo } from "./Headings"
import { Unordered, ListItem } from "./Lists"

const InfoSection = () => {
  return (
    <Wrapper id="section-info">
      <Content>
        <CenterText>
          <HeadingInfo>
            When<span className="underline"></span>
          </HeadingInfo>
        </CenterText>
        <Unordered>
          <ListItem>Saturday, December 31, 2022</ListItem>
          <ListItem>New Years Eve</ListItem>
          <ListItem>6:30pm</ListItem>
        </Unordered>
      </Content>
      <Content>
        <CenterText>
          <HeadingInfo>
            Where<span className="underline"></span>
          </HeadingInfo>
        </CenterText>
        <Unordered>
          <ListItem>Bedford Village Inn</ListItem>
          <ListItem>2 Olde Bedford Way</ListItem>
          <ListItem>Bedford, New Hampshire 03110</ListItem>
        </Unordered>
      </Content>
    </Wrapper>
  )
}

export default InfoSection

const Wrapper = styled.section`
  width: 100%;
  min-height: 50vh;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 8rem 6rem;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 100vh;
  }
`

const Content = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .underline {
    width: 75%;
    border-bottom: 4px solid var(--color-primary-text-dark);
    display: inline-block;
  }
`

const CenterText = styled.div`
  text-align: center;
  line-height: 0.5;
`
