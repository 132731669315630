import styled from "styled-components"

export const CloseBtn = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  margin: 1rem;
  font-weight: 700;
  font-size: 2rem;
  align-self: flex-end;
  cursor: pointer;
`
