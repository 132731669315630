import styled from "styled-components"

// links
export const NavLink = styled.a`
  &:link,
  &:active,
  &:visited {
    color: var(--color-primary-link);
    font-size: 1.1rem;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      border-bottom: 1px solid #fff;
    }
  }
`
export const DropdownLink = styled.a`
  &:link,
  &:active,
  &:visited {
    color: var(--color-primary-link);
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: none;
    display: block;
    margin: 2rem 2rem;

    &:hover {
      transform: scale(1.1);
    }
  }
`
export const ExternalLink = styled.a`
  color: var(--color-heading-gold);
  background: var(--color-primary-text-dark);
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  margin: 1.5rem 0 0 0;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 3px;
  transition: all 0.2s;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);

  &:hover {
    transform: scale(1.03);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  }
`
