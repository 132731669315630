import React from "react"
import styled from "styled-components"

const Wrapper = styled.footer`
  width: 100%;
  background: rgb(46, 46, 46);
  color: #fff;
  padding: 1rem 0;
  text-align: center;

  p {
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <p>Email Questions to Allie at afinn890@gmail.com</p>
      <p>© 2022 Jake Sheehan</p>
    </Wrapper>
  )
}

export default Footer
