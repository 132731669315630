import React from "react"
import styled from "styled-components"
// components
import { Heading2 } from "./Headings"
import { Unordered, ListItem } from "./Lists"
import { ExternalLink } from "./Links"

const HotelCard = props => {
  return (
    <Wrapper>
      <Heading2>{props.name}</Heading2>
      <Unordered>
        <ListItem>{props.street}</ListItem>
        <ListItem>{props.city}</ListItem>
        <ExternalLink
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Book A Room
        </ExternalLink>
      </Unordered>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
  padding: 2rem;
  border-radius: 3px;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.7);
  min-width: 25%;
`

export default HotelCard
