import React from "react"
import styled, { keyframes } from "styled-components"
import { CloseBtn } from "./Buttons"
import { DropdownLink } from "./Links"

const DropdownMenu = props => {
  return (
    <Wrapper>
      <CloseBtn type="button" onClick={() => props.closeMenu()}>
        X
      </CloseBtn>
      <DropdownLink onClick={() => props.closeMenu()} href="#section-title">
        Home
      </DropdownLink>
      <DropdownLink
        onClick={() => props.closeMenu()}
        href="https://www.crateandbarrel.com/gift-registry/allison-finn/r6573451"
        target="_blank"
        rel="noopener noreferrer"
      >
        Registry
      </DropdownLink>
      <DropdownLink onClick={() => props.closeMenu()} href="#section-info">
        Info
      </DropdownLink>
      <DropdownLink
        onClick={() => props.closeMenu()}
        href="#section-accommodations"
      >
        Accommodations
      </DropdownLink>
      <DropdownLink onClick={() => props.closeMenu()} href="#section-schedule">
        Schedule
      </DropdownLink>
    </Wrapper>
  )
}

const enterAnimation = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(0);
}
`

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;
  background-color: rgba(46, 46, 46, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  animation: ${enterAnimation};
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-delay: 0s;
`

export default DropdownMenu
