import React from "react"
import styled from "styled-components"
// image
import background from "../images/inn_at_night.webp"
// components
import { Heading1, Heading2 } from "./Headings"
// styles
import "@fontsource/the-nautigal"

const TitleSection = () => {
  return (
    <Wrapper id="section-title">
      <Content>
        <div className="hr-center">
          <hr />
          <Heading2 color="white">the wedding of</Heading2>
          <hr />
        </div>
        <Heading1 color="white">
          jake <span className="amp">&</span> allie
        </Heading1>
        <div className="hr-center">
          <hr />
          <Heading2 color="white">december 31, 2022</Heading2>
          <hr />
        </div>
      </Content>
    </Wrapper>
  )
}

export default TitleSection

const Wrapper = styled.section`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
    url(${background});
  background-size: cover;
  background-position: 50% 100%;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  .hr-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  hr {
    flex-grow: 1;
  }

  .amp {
    font-family: "The Nautigal";
  }

  @media (max-width: 768px) {
    max-height: 100vh;
  }
`

const Content = styled.div`
  text-align: center;
`
