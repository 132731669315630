import React, { useState } from "react"
import "../styles/global.css"
import styled from "styled-components"
import "@fontsource/playfair-display"

// Components
import NavBar from "../components/NavBar"
import TitleSection from "../components/TitleSection"
import InfoSection from "../components/InfoSection"
import { AccommodationsSection } from "../components/AccommodationsSection"
import DropdownMenu from "../components/DropdownMenu"
import ScheduleSection from "../components/ScheduleSection"
import Footer from "../components/Footer"

const Home = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev)
  }

  return (
    <Wrapper>
      <header>
        <NavBar onDropdownOpen={toggleDropdown} />
      </header>
      <main>
        {dropdownOpen && <DropdownMenu closeMenu={toggleDropdown} />}
        <TitleSection />
        <InfoSection />
        <AccommodationsSection />
        <ScheduleSection />
      </main>
      <Footer />
    </Wrapper>
  )
}

export default Home

const Wrapper = styled.div`
  font-family: "Playfair Display";
`
